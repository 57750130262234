.btn {
    background-color: #39b54a;
    color: $color-white;
    border: 1px solid #373737;
    border-radius: $round-corner-radius;
    padding: 15px;
    font-size: $font-size-24;
    width: 75%;
    transition: all 0.2s ease-in;
    position: relative;
    bottom: 190px;
    right: -88px;
    span.btn-em {
        font-size: $font-size-24;
        text-transform: uppercase;
        font-weight: bold;
    }
    &:hover {
        transition: all 0.2s ease-in;
        font-size: $font-size-24;
        background-color: lighten($color-primary, 40%);
        color: $color-black;
    }
}
