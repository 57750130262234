.module-header {
    position: relative;
    padding-bottom: 58px;
    margin-bottom: -2px;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 94%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: 5vw;
            font-weight: 400;
            color: $color-text-contrast;
            text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 1.25vw;
            font-weight: 300;
            text-transform: initial;
            color: #fff;
            background: #39b54a;
            text-shadow: 0 0 10px $color-black;
            font-family: $font-family-1;
            padding: 15px 0;
        }

    }
}
@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 6vw;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 7vw;
            }
            &--subtitle {
                font-size: 5vw;
            }
        }
    }
}
@media screen and (max-width: $size-xs-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 8vw;
            }
            &--subtitle {
                font-size: 6vw;
            }
        }
    }
}
