// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';

p {
    font-family: 'Fira Sans Condensed';
}
.bursts .burst .text-container .border p {
    font-family: 'Bebas Neue';
    color: #fff;
    font-weight: normal;
    font-size: $font-size-70;
    line-height: 1.05;
    text-align: center;
}
.bursts-container a:hover .burst p {
    display: none;
}
h1, h2, h3, h4, h5 {
    font-family: 'Bebas Neue';
}

/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocReve {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0;

    h3 {
        font-size: $font-size-70;
        color: #39b54a;
        font-weight: normal;
    }
    p {
        font-size: $font-size-24;
        color: #1b1b1b;
        font-weight: normal;
    }

    .logo {
        text-align: -webkit-right;
    }
}

#blocParallax {
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 285px 0;
    text-align: center;

    p {
        font-size: $font-size-48;
        color: #1b1b1b;
        font-weight: normal;
        line-height: 1.3;

        span {
            font-family: 'Bebas Neue';
            font-size: $font-size-60;
            color: #000;
            font-weight: normal;
        }
    }
}

#bloc50-50 {
    display: flex;
    padding: 100px 0;
    justify-content: space-evenly;

    .left-side {
        text-align: right;
    }

    h3 {
        font-size: $font-size-70;
        color: #39b54a;
        font-weight: normal;
    }
    p.first {
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-24;
        color: #1b1b1b;
        font-weight: normal;
    }
}

#blocObjectifs {
    background: url("../images/accueil_section05_bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 220px 0 310px 0;
    display: flex;
    justify-content: space-evenly;

    h3 {
        font-size: $font-size-70;
        color: #fff;
        font-weight: normal;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-size: 1.8rem;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 25px;
        padding-left: 60px;
        line-height: 1.25;
    }
}

#blocLogo {
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/
.filter-section {
      padding: 30px 15px;
      .container-fluid.row {
            margin: 0;
            padding: 0;
      }

      .tools {
            display: flex;
            align-items: center;
            justify-content: center;
            & > div {
              padding: 15px 80px;
            }

            select {
              color: $color-black;
              padding: 5px 20px;
              background: $color-1;
              text-transform: uppercase;
            }
            @media screen and (max-width: $size-xs-max){
                flex-direction: column;
                & > div {
                  padding: 15px 20px;
                }
                .pagination {
                    order: -1;
                }
            }
      }
}

.blocRealisations {
    padding: 0;


    .bloc {
        display: flex;
        padding-bottom: 50px;
        .image, .texte {
            width: 50%;
        }

        .image {
              overflow: hidden;

              position: relative;
              min-height: 350px;
              transition: .5s;
              a.link {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    position: absolute;
                    transition: .5s;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
              }
              &:hover {
                  a.link {
                      transform: scale(1.2);
                      transition: .5s;
                  }
              }
        }
        .texte {
            padding: 50px;
            background: #39b54a;
        }
        h3 {
            font-size: $font-size-70;
            color: #fff;
            padding-bottom: 15px;
        }
        p {
            font-size: $font-size-24;
            color: #1b1b1b;
            font-weight: normal;
        }
    }
    .bloc.last {
        padding-bottom: 0;
    }


}


.product-popup {
    .product-wrapper {
        @extend .flex;
        // @extend .flex-wrap;
        @extend .justify-start;
        @extend .items-center;
        .product-image {
            flex: 1;
        }
        .product-info {
            flex: 1;
            padding: 10px 30px 0;
        }
    }
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 80%;
    margin: 20px auto;
    div.image {

    }
    img.mfp-img {
        padding: 0;
    }
    .title, .categories {
        padding: 20px 0;
        font-size: 20px;
        font-weight: 700;
    }
    .title {
        text-transform: uppercase;
    }

    div.info {
        @extend .flex;
        @extend .items-center;
        @extend .justify-between;
    }

    div.description {
        padding: 10px 0;
        color: $color-grey;
        font-size: 20px;
        line-height: 28px;
        text-align: justify;
    }
    @media screen and (max-width: $size-xs-max) {
         .title, div.description {
            font-size: $font-size-16;
            text-align: left;
            padding: 0;
        }
        .product-wrapper {
            flex-direction: column;
            .product-info .info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocIntro {
    display: flex;
    justify-content: space-around;
    padding: 100px 2%;

    h2 {
        font-size: $font-size-70;
        color: #39b54a;
        text-align: right;
        line-height: 1.3;
    }
    p {
        font-size: $font-size-24;
        color: #1b1b1b;
        font-weight: normal;
    }
    p.first {
        padding-bottom: 35px;
    }
    p.vert {
        color: #39b54a;
        font-weight: bold;
    }
    p.vertRegulier {
        color: #39b54a;
        font-weight: normal;
        text-align: right;
    }
}

#blocCoordonnees {
    display: flex;
    padding: 85px 2%;
    background: #39b54a;
    justify-content: space-around;

    h3 {
        font-size: $font-size-70;
        color: #fff;
        text-align: right;
        line-height: 1.3;
    }

    .adresse, .telephone, .courriel {
        display: flex;
        align-items: end;

        .info {
            padding-left: 25px;

            p {
                font-size: $font-size-30;
                color: #fff;
                font-weight: normal;
                line-height: 1.25;
                margin: 0;
            }
        }
    }
    .adresse {
        padding-bottom: 35px;
    }
    .telephone {
        padding-bottom: 50px;
    }
}

section.section-form {
    padding: 50px 0;
}


/*******************************************************************************
* REVÊTEMENT EXTÉRIEUR
*******************************************************************************/

#blocBackground {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 2%;
    background: url("../images/revetement_section03_bg.jpg") no-repeat;
    background-size: cover;
    align-items: center;

    h3 {
        font-size: $font-size-70;
        color: #fff;
        padding-bottom: 35px;
    }
    p {
        font-size: $font-size-24;
        color: #fff;
        font-weight: normal;
        text-align: right;
        line-height: 1.2;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 25px;
        padding-left: 60px;
        line-height: 1.25;
    }
}

#blocBackground_portesFenetres {
    display: flex;
    justify-content: space-around;
    padding: 150px 2%;
    background: url("../images/revetement_section05_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-70;
        color: #fff;
        padding-bottom: 35px;
    }
    p {
        font-size: $font-size-24;
        color: #fff;
        font-weight: normal;
        text-align: right;
        line-height: 1.2;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 30px;
        padding-left: 60px;
        line-height: 1.25;
    }
}


/*******************************************************************************
* AGRANDISSEMENT DE MAISON
*******************************************************************************/

#blocBackground_agrandissement {
    display: flex;
    justify-content: center;
    padding: 115px 2%;
    background: url("../images/agrandissement_section03_bg.jpg") no-repeat;
    background-size: cover;

    li {
        text-transform: initial;
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 30px;
        padding-left: 60px;
        line-height: 1.25;
    }
}

#texteBackground {
    padding: 75px 10%;

    .texteIntro {
        text-align: center;
        padding-bottom: 50px;

        p.first {
            padding-bottom: 25px;
        }
        p {
            font-size: $font-size-24;
            color: #1b1b1b;
            font-weight: normal;
        }
    }

    .background {
        display: flex;
        justify-content: space-around;
        background: url("../images/encadre_bg.jpg") no-repeat;
        background-size: cover;
        padding: 50px 0;

        h3 {
            font-size: $font-size-70;
            color: #fff;
            line-height: 1.3;
            padding-bottom: 25px;
        }
        .bloc {
            display: flex;
            align-items: end;
            padding-bottom: 25px;
            &:last-child{
                padding-bottom: 0;
            }

            .titre {
                padding-right: 20px;

                h3 {
                    text-shadow: 0 0 10px black;
                }
            }

            p {
                font-size: $font-size-24;
                color: #fff;
                font-weight: normal;
            }
        }
    }
}


/*******************************************************************************
* RÉNOVATION GÉNÉRALE
*******************************************************************************/

#blocBackground_renovation {
    display: flex;
    padding: 85px 5%;
    background: url("../images/renovation_section03_bg.jpg") no-repeat;
    background-size: cover;

    p {
        font-size: $font-size-24;
        color: #fff;
        font-weight: normal;
        text-align: right;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 30px;
        padding-left: 60px;
        line-height: 1.25;
    }

    .left-side {
        width: 30%;
        margin-right: 10%;
    }
}

#texteBackground_renovation {
    padding: 75px 10%;

    .titreTexte {
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;

        h3 {
            font-size: 3.5rem;
            color: #39b54a;
            text-align: right;
            line-height: 1.3;
        }
        p {
            font-size: $font-size-24;
            color: #1b1b1b;
            font-weight: normal;
        }
    }

    .background {
        display: flex;
        justify-content: space-around;
        background: url("../images/encadre_bg.jpg") no-repeat;
        background-size: cover;
        padding: 50px 0;

        h3 {
            font-size: $font-size-70;
            color: #fff;
            line-height: 1.3;
            padding-bottom: 25px;
        }
        .bloc {
            display: flex;
            align-items: end;
            padding-bottom: 25px;
            &:last-child{
                padding-bottom: 0;
            }

            .titre {
                padding-right: 20px;

                h3 {
                    text-shadow: 0 0 10px black;
                }
            }

            p {
                font-size: $font-size-24;
                color: #fff;
                font-weight: normal;
                line-height: 1.3;
            }
        }
    }
}


/*******************************************************************************
* SYSTÈME INTÉRIEUR
*******************************************************************************/

#blocBackground_systeme {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 2%;
    background: url("../images/systeme_section03_bg.jpg") no-repeat;
    background-size: cover;
    align-items: center;

    p {
        font-size: $font-size-48;
        color: #fff;
        font-weight: normal;
        text-align: center;
        line-height: 1.5;
    }
}

#blocBackground_systeme2 {
    display: flex;
    justify-content: space-around;
    padding: 85px 2%;
    background: url("../images/systeme_section05_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-70;
        color: #fff;
        padding-top: 55px;
    }
    p {
        font-size: $font-size-24;
        color: #fff;
        font-weight: normal;
        text-align: right;
        line-height: 1.2;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
        list-style: none;
        margin-bottom: 30px;
        padding-left: 60px;
        line-height: 1.25;
    }
}


/*******************************************************************************
* TOITURE
*******************************************************************************/

#bloc50-50_toiture {
    display: flex;

    .left-side, .right-side {
        width: 50%
    }
    .right-side {
        background: #39b54a;
        padding: 7%;

        p {
            font-size: $font-size-30;
            color: #fff;
            font-weight: normal;
        }
    }

}

#blocBackground_toiture {
    display: flex;
    justify-content: space-around;
    padding: 150px 2%;
    background: url("../images/toiture_section05_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-70;
        color: #fff;
    }
    p {
        font-size: $font-size-24;
        color: #fff;
        font-weight: normal;
    }
}

#blocToitures {
    background: #39b54a;
    padding: 100px 2% 50px 2%;

    .bloc {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: 25px;

        h3 {
            font-size: $font-size-60;
            color: #fff;
            line-height: 1.2;
            padding-bottom: 25px;
            text-shadow: 0 0 10px black;
            text-align: right;
        }
        p {
            font-size: $font-size-24;
            color: #fff;
            font-weight: normal;
        }
        ul {
            padding-left: 50px;
          li {
                font-size: $font-size-24;
                color: #fff;
                font-weight: normal;
                text-transform: initial;
                padding-bottom: 10px;
            }
        }
    }

    .bloc2 {
        .icon {
            margin-left: 40px;
        }
    }
}


/*******************************************************************************
* CONSTRUCTION AGRICOLE
*******************************************************************************/

#blocBackground_agricole {
    display: flex;
    justify-content: center;
    padding: 130px 2%;
    background: url("../images/construction_section03_bg.jpg") no-repeat;
    background-size: cover;

    ul {
    padding-left: 395px;

        li {
            text-transform: initial;
            color: #fff;
            font-size: $font-size-30;
            font-weight: normal;
            background: url(../images/accueil_section05_icon_checkmark.png) no-repeat left;
            list-style: none;
            margin-bottom: 20px;
            padding-left: 60px;
            line-height: 1.25;
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {

.module-header--caption--subtitle {
    padding: 16px 0;
}
}

@media screen and (max-width: 1855px) {

.module-header--caption {
    top: 94%;
}
.blocRealisations .bloc .texte {
    padding: 35px;
}
}

@media screen and (max-width: 1785px) {

.module-header--caption--subtitle {
    padding: 18px 0;
}
#bloc50-50_toiture .right-side {
    padding: 6% 5%;
}
}

@media screen and (max-width: 1700px) {

.module-header--caption {
    top: 93%;
}
.module-header--caption--subtitle {
    padding: 20px 0;
}
}

@media screen and (max-width: 1680px) {

.blocRealisations .bloc h3 {
    font-size: 3rem;
}
.blocRealisations .bloc p {
    font-size: 1rem;
}
}

@media screen and (max-width: 1595px) {

#bloc50-50_toiture .right-side p {
    font-size: 1.3rem;
}
}

@media screen and (max-width: 1575px) {

#blocIntro .texte {
    width: 45%;
}
#blocIntro p.first br, #blocIntro p.vert br, #blocBackground_renovation p br, #texteBackground_renovation .titreTexte p br, #texteBackground_renovation .background .bloc p br {
    display: none;
}
#texteBackground_renovation .titreTexte .texte {
    width: 45%;
}
#texteBackground_renovation .background {
    padding: 50px;
}
#texteBackground_renovation .background .left-side, #texteBackground_renovation .background .right-side {
    width: 40%;
}
#blocBackground_systeme p br, #blocIntro p br, #blocToitures .bloc p br{
    display: none;
}
#blocIntro p.vertRegulier br {
    display: block;
}
#blocToitures .bloc .texte {
    width: 50%;
}
#blocToitures .bloc2 .icon {
    margin-left: 70px;
}
}

@media screen and (max-width: 1550px) {

#blocObjectifs {
    padding: 220px 0;
}
}

@media screen and (max-width: 1505px) {

.blocRealisations .bloc h3 {
    font-size: 2.8rem;
}
#blocIntro .titre {
    padding-right: 55px;
}
}

@media screen and (max-width: 1495px) {

#blocIntro p br {
    display: none;
}
#blocIntro .texte {
    width: 50%;
}
}

@media screen and (max-width: 1480px) {

#blocBackground_portesFenetres .right-side {
    width: 40%;
}
#blocBackground h3 {
    text-align: right;
}
#blocBackground .left-side {
    width: 45%;
}
#texteBackground .background .bloc p br {
    display: none;
}
#texteBackground .background {
    padding: 80px;
}
#texteBackground .background .left-side, #texteBackground .background .right-side {
    width: 40%;
}
}

@media screen and (max-width: 1460px) {

.blocRealisations .bloc h3 {
    font-size: 2rem;
}
}

@media screen and (max-width: 1410px) {

.module-header--caption {
    top: 92%;
}
#blocBackground_toiture .right-side {
    width: 50%;
}
#blocBackground_toiture p br {
    display: none;
}
}

@media screen and (max-width: 1380px) {

.bursts .burst .text-container .border p {
    font-size: 3rem;
}
#blocReve {
    padding: 50px 5%;
    flex-direction: column;
}
#blocReve h3 {
    padding-bottom: 25px;
}
#blocParallax {
    padding: 200px 0;
}
#bloc50-50 {
    padding: 100px 5%;
    flex-direction: column;
}
#bloc50-50 .left-side {
    text-align: left;
    padding-bottom: 25px;
}
#bloc50-50 .left-side h3 br, #bloc50-50 .right-side p br {
    display: none;
}
#blocObjectifs {
    padding: 170px 0;
}
// .blocRealisations .bloc {
//     flex-direction: column;
// }
// .blocRealisations .bloc .image, .blocRealisations .bloc .texte {
//     margin: auto;
// }
.module-header--caption {
    top: 92%;
}
#bloc50-50_toiture .right-side {
    padding: 6% 3%;
}
#blocIntro h2 {
    font-size: 3.4rem;
}
}

@media screen and (max-width: 1340px) {

#texteBackground .texteIntro p.first br {
    display: none;
}
}


@media screen and (max-width: 1310px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.phone, nav.module-menu .menu-navbar .wrapper.right .main-menu-top > ul li.icon.facebook {
    margin-left: 1rem;
}
.module-header--caption--subtitle {
    font-size: 1.8vw;
}
.blocRealisations .bloc p br {
    display: none;
}
.blocRealisations .bloc h3 {
    font-size: 2.8rem;
}
#blocIntro .texte {
    width: 45%;
}
.module-header--caption--subtitle {
    padding: 18px 0;
}
#texteBackground .background {
    padding: 45px 25px;
}
#texteBackground_renovation .background {
    border-radius: 10px;
}
#blocBackground_renovation .left-side {
    width: 40%;
}
}

@media screen and (max-width: $size-md-max) {

.bursts .burst .text-container .border p {
    font-size: 2.5rem;
}
#blocObjectifs {
    padding: 115px 0;
}
.module-header--caption {
    top: 90%;
}
.module-header--caption--subtitle {
    font-size: 2vw;
}
#blocBackground_portesFenetres {
    padding: 100px 5%;
    flex-direction: column;
}
#blocBackground_portesFenetres .right-side {
    width: 100%;
}
#blocBackground_renovation .left-side {
    width: 55%;
}
#blocToitures .bloc .texte {
    width: 43%;
}
#bloc50-50_toiture {
    flex-direction: column;
}
#bloc50-50_toiture .left-side, #bloc50-50_toiture .right-side {
    width: 93%;
    margin: auto;
}
#bloc50-50_toiture .right-side p br {
    display: none;
}
}

@media screen and (max-width: $size-sm-max) {

.bursts .bursts-container {
    flex-direction: column;
}
.bursts .bursts-container img{
    width: 100%;
}
.bursts .burst .text-container .border p {
    font-size: 3.5rem;
}
.blocRealisations .bloc {
    flex-direction: column;
}
.blocRealisations .bloc .image, .blocRealisations .bloc .texte {
    margin: auto;
}
#blocParallax {
    padding: 155px 0;
}
#blocObjectifs {
    padding: 80px 5%;
    flex-direction: column;
}
#blocObjectifs .titre {
    padding-bottom: 25px;
}
.module-header--caption--subtitle {
    font-size: 2.5vw;
}
.blocRealisations .bloc .image, .blocRealisations .bloc .texte {
    width: 75%;
}
#blocIntro {
    flex-direction: column;
}
#blocIntro .texte {
    width: 100%;
}
#blocIntro .titre {
    padding-right: 0;
}
#blocIntro h2 {
    text-align: left;
}
#blocIntro {
    padding: 100px 5%;
}
#blocCoordonnees {
    padding: 85px 5%;
    flex-direction: column;
}
#blocCoordonnees h3 {
    text-align: left;
    padding-bottom: 55px;
}
.module-header--caption {
    top: 87%;
}
.module-header--caption--subtitle {
    padding: 19px 0;
}
#blocBackground {
    padding: 100px 5%;
    flex-direction: column;
}
#blocBackground h3, #blocBackground p {
    text-align: left;
}
#blocBackground .left-side {
    width: 100%;
    padding-bottom: 50px;
}
#texteBackground .background {
    padding: 50px;
    flex-direction: column;
    border-radius: 10px;
}
#texteBackground .background .left-side, #texteBackground .background .right-side {
    width: 100%;
}
#blocBackground_renovation {
    flex-direction: column;
}
#blocBackground_renovation .left-side {
    width: 100%;
    margin: 0;
}
#blocBackground_renovation p {
    text-align: left;
    padding-bottom: 25px;
}
#texteBackground_renovation .titreTexte {
    flex-direction: column;
}
#texteBackground_renovation .titreTexte h3 {
    text-align: left;
}
#texteBackground_renovation .titreTexte .texte {
    width: 100%;
}
#texteBackground_renovation .background {
    flex-direction: column;
}
#texteBackground_renovation .background .left-side, #texteBackground_renovation .background .right-side {
    width: 100%;
}
#blocIntro p.vertRegulier {
    text-align: left;
    padding-bottom: 25px;
}
#blocBackground_systeme2 {
    padding: 85px 5%;
    flex-direction: column;
}
#blocBackground_systeme2 h3 {
    padding-top: 0;
    padding-bottom: 35px;
}
#blocIntro p.vertRegulier br {
    display: none;
}
#blocBackground_agricole ul {
    padding-left: 0;
}
#blocBackground_toiture {
    padding: 100px 5%;
    flex-direction: column;
}
#blocBackground_toiture .right-side {
    width: 100%;
}
#blocBackground_toiture .left-side {
    padding-bottom: 25px;
}
#blocToitures {
    padding: 100px 5% 50px 5%;
}
#blocToitures .bloc {
    flex-direction: column;
    padding-bottom: 35px;
}
#blocToitures .bloc .texte {
    width: 100%;
}
#blocToitures .bloc2 .icon {
    margin-left: 0;
}
#blocToitures .bloc .icon {
    padding-bottom: 20px;
}
#blocToitures .bloc h3 br {
    display: none;
}
#blocToitures .bloc h3 {
    text-align: left;
}
#bloc50-50_toiture .right-side {
    padding: 7%;
}
}


@media screen and (max-width: $size-xs-max) {

#blocParallax {
    padding: 100px 0;
}
#blocParallax p br{
    display: none;
}
#blocLogo {
    flex-direction: column;
    align-items: center;
}
.module-header--caption {
    top: 94%;
}
.module-header--caption--subtitle {
    padding: 20px 0;
}
.module-header--caption--subtitle {
    font-size: 6vw;
}
#texteBackground .background, #texteBackground_renovation .background {
    padding: 50px 25px;
}
#texteBackground, #texteBackground_renovation {
    padding: 75px 5%;
}
}
